<template>
  <div :class="$style.chart" ref="cpie">
    <div
      v-if="chartData.length === 0 || !chartData"
      style="display: flex; justify-content: center; height: 100%"
    >
      <EmptyContent />
    </div>
    <div v-else ref="circleCharts" :class="$style.content" />
  </div>
</template>

<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import EmptyContent from '@/components/empty-content/empty-content.vue';
import { nowSize } from '@/utils/common';
import { Emitter } from '@/views/screen/components/screen-utils';
import { crossStorageModule } from '@/enum/store';
import theme from '@/utils/theme/theme';

@Component({
  components: {
    EmptyContent,
  },
})
export default class CirclePie extends Vue {
  @Prop({ type: String, default: '6rem' }) chartWidth;
  @Prop({ type: String, default: '2.4rem' }) chartHeight;
  @Prop({ type: Boolean, default: false }) showLegend;
  @Prop({ type: Array, default: () => [], required: true }) chartData;
  @Prop({ type: String, required: true }) title;
  @Prop({ type: String, default: '' }) unit;
  @Prop({
    type: Array,
    default: () => [
      '#4FA2FF',
      '#00C56E',
      '#FFCC37',
      '#EF6666',
      '#0065C4',
      '#07B9B9',
      '#3BA272',
      '#EA7CCC',
      '#91CC75',
      '#FAC858',
    ],
  })
  colorArray;

  @crossStorageModule.State('skin') skin;
  @Watch('skin')
  changeSkin() {
    this.initChart();
  }

  @Watch('chartData')
  changeData(newVal) {
    if (newVal && newVal.length) {
      this.$nextTick(() => {
        this.initChart();
      });
    } else {
      if (this.myChart) {
        this.myChart.dispose();
        this.myChart = null;
      }
    }
  }
  chartResize() {
    setTimeout(() => {
      const rect = this.$refs.cpie.getBoundingClientRect();
      const opt = {
        width: rect.width,
        height: rect.height,
      };
      if (this.myChart) {
        this.myChart.resize(opt);
      }
    }, 100);
  }
  mounted() {
    this.initChart();
    Emitter.$on('fullscreen', this.chartResize);
  }
  beforeDestroy() {
    Emitter.$off('fullscreen', this.chartResize);
  }
  myChart = null;
  initChart() {
    if (!this.myChart) {
      const el = this.$refs.circleCharts;
      if (!el) return;
      this.myChart = echarts.init(el);
    }
    if (this.chartData.length === 0) return;
    const legendData = this.chartData.map(v => v.name);
    let toltalNum = 0;
    this.chartData.forEach(element => {
      toltalNum += element.value;
    });
    const themeMode = theme['dark'];
    const options = {
      color: this.colorArray,
      grid: {
        left: '3%',
        bottom: '2%',
        top: '2%',
        containLabel: true,
      },
      title: {
        text: `{a| ${this.title}\n}{b|${toltalNum}}`,
        x: this.showLegend ? '32%' : '44%',
        y: '42%',
        textAlign: 'center',
        textStyle: {
          textAlign: 'center',
          rich: {
            a: {
              fontSize: nowSize(18),
              fontWeight: '400',
              color: themeMode.font,
              padding: [0, 0, nowSize(10)],
            },
            b: {
              fontSize: nowSize(18),
              color: themeMode.font,
              fontWeight: 'bold',
            },
          },
        },
      },
      tooltip: {
        trigger: 'item',
        formatter: ({ name }) => {
          if (this.chartData.length) {
            const item = this.chartData.filter(item => item.name === name)[0];
            if (item.percent) {
              return `${name}: ${item.value}${this.unit}, ${item.type}${item.percent}`;
            } else {
              return `${name}: ${item.value}${this.unit}`;
            }
          }
        },
      },
      legend: {
        data: legendData,
        show: this.showLegend,
        orient: 'vertical',
        right: nowSize(10),
        top: 'middle',
        textStyle: {
          color: themeMode.font,
          overflow: 'break',
          width: 60,
        },
        pageTextStyle: {
          color: themeMode.font,
        },
        formatter: name => {
          if (this.chartData.length) {
            const item = this.chartData.filter(item => item.name === name)[0];
            return `${name} ${item.value}${this.unit}`;
          }
        },
        type: 'scroll',
      },
      series: [
        {
          type: 'pie',
          center: [this.showLegend ? '33%' : '45%', '50%'],
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderColor: themeMode.pieBorder,
            borderWidth: nowSize(3),
          },
          label: {
            show: true,
            formatter: ({ name }) => {
              if (this.chartData.length) {
                const item = this.chartData.filter(
                  item => item.name === name,
                )[0];
                return `${name} ${item.value}${this.unit}`;
              }
            },
            color: themeMode.font,
            width: nowSize(60),
            overflow: 'break',
          },
          labelLine: {
            show: true,
          },
          data: this.chartData,
        },
      ],
    };
    this.myChart.setOption(options, true);
  }
}
</script>
<style lang="less" module>
.chart {
  // height: calc(100% - 0.3rem);
  height: 100%;
  .content {
    height: 100%;
  }
}
</style>
