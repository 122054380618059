<template>
  <div :class="$style.chart" ref="cbar">
    <div
      ref="barChart"
      :style="{ width: chartWidth, height: chartHeight }"
    ></div>
  </div>
</template>

<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import EmptyContent from '@/components/empty-content/empty-content.vue';
import { nowSize } from '@/utils/common';
import { crossStorageModule } from '@/enum/store';
import theme from '@/utils/theme/theme';
import { Emitter } from '../components/screen-utils';

@Component({
  components: {
    EmptyContent,
  },
})
export default class CommonBar extends Vue {
  @Prop({ type: String, default: '100%' }) chartWidth;
  @Prop({ type: String, default: '100%' }) chartHeight;
  @Prop({ type: Array, default: () => [] }) chartData; // 数据示例：[{ name: 'PM10超标报警', value: 8 }]
  @Prop({ type: String, default: '' }) chartUnit;
  @Prop({ type: String, default: '' }) chartTitle;
  @Prop({ type: Boolean, default: false }) isTilt; // axisLabel是否倾斜
  @Prop({ type: Number, default: 15 }) barWidth;

  @crossStorageModule.State('skin') skin;
  @Watch('skin')
  changeSkin() {
    this.initChart();
  }

  chartResize() {
    setTimeout(() => {
      const rect = this.$refs.cbar.getBoundingClientRect();
      const opt = {
        width: rect.width,
        height: rect.height,
      };

      this.myChart?.resize(opt);
    }, 100);
  }
  mounted() {
    Emitter.$on('fullscreen', this.chartResize);
  }
  beforeDestroy() {
    Emitter.$off('fullscreen', this.chartResize);
    this.myChart.off('click', this.handleEvent);
  }
  @Watch('chartData', { deep: true, immediate: true })
  changeData(newVal) {
    if (newVal && newVal.length) {
      this.isNoData = false;
      this.$nextTick(() => {
        this.initChart();
      });
    } else {
      if (this.myChart) {
        this.myChart.dispose();
        this.myChart = null;
      }
      this.isNoData = true;
    }
  }
  handleEvent(params) {
    this.$emit('click', params);
  }
  isNoData = true;
  myChart = null;
  initChart() {
    if (!this.myChart) {
      const el = this.$refs.barChart;
      if (!el) return;
      this.myChart = echarts.init(el);
    }
    if (!this.chartData.length) {
      return;
    }
    this.myChart.on('click', this.handleEvent);
    const themeMode = theme['dark'];
    const xData = this.chartData.map(v => v.name);
    const options = {
      color: ['#4FA2FF'],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        backgroundColor: themeMode.mainBg,
        textStyle: {
          color: themeMode.font,
          fontSize: nowSize(14),
        },
        extraCssText: 'border-radius:5px;border-color: var(--primary-fade-80)',
        padding: [nowSize(10)],
        formatter: `{b0}: {c0}${this.chartUnit}`,
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        top: '5%',
        containLabel: true,
      },
      xAxis: [
        {
          type: 'category',
          data: xData,
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: this.isTilt
            ? {
                width: nowSize(60),
                overflow: 'truncate',
                ellipsis: '...',
                color: themeMode.font,
                rotate: nowSize(30),
              }
            : {
                width: nowSize(60),
                color: themeMode.font,
              },
        },
      ],
      yAxis: [
        {
          type: 'value',
          axisLabel: {
            color: themeMode.font,
          },
          splitLine: {
            lineStyle: {
              color: themeMode.border,
            },
          },
        },
      ],
      series: [
        {
          name: this.chartTitle,
          type: 'bar',
          barWidth: nowSize(this.barWidth),
          data: this.chartData,
          // label: {
          //   show: true,
          //   color: themeMode.font,
          //   position: 'top',
          // },
        },
      ],
    };
    this.myChart.setOption(options, true);
  }
}
</script>
<style lang="less" module>
.chart {
  height: 100%;
}
</style>
