<template>
  <div ref="chartsRef" class="h-full w-full overflow-hidden"></div>
</template>

<script>
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import { nowSize } from '@/utils/common';
import { Emitter } from '@/views/screen/components/screen-utils';

/**@typedef {import('echarts/types/src/export/option').EChartsOption} EChartsOption*/

/**@typedef {import('echarts').ECharts} ECharts*/

@Component
export default class PieChart extends Vue {
  @Prop({
    type: Array,
    default: () => [
      { value: 1048, name: 'PM2.5预警' },
      { value: 735, name: 'PM2.5报警' },
      { value: 580, name: 'PM10预警' },
      { value: 484, name: 'PM10报警' },
    ],
  })
  chartData;
  @Prop({ type: Number, default: 140 }) size;
  /**@name legend前面部分文字的宽度 */
  @Prop({ type: Number, default: 80 }) legendWidth;
  @Prop({ type: String, default: '15%' }) left;
  @Prop({ type: String, default: '15%' }) right;
  @Prop({ type: Number, default: 10 }) legendItemGap;
  @Prop({
    type: Array,
    default: () => [
      '#38D7DD',
      '#FEBD7B',
      '#7AAFFF',
      '#F4FD7D',
      '#FFFFFF',
      '#3ba272',
      '#fc8452',
      '#9a60b4',
      '#ea7ccc',
      '#4FA2FF',
      '#00C56E',
      '#FFCC37',
      '#EF6666',
      '#0065C4',
      '#07B9B9',
      '#EA7CCC',
      '#91CC75',
      '#FAC858',
    ],
  })
  colorArray;
  @Prop({
    type: Object,
    default: () => ({
      show: false,
    }),
  })
  labelOption;
  @Prop({ type: Boolean, default: false }) showEllipsis;
  @Prop({ type: Number, default: 4 }) fontLength;

  /**@type { ECharts } */
  chartsInstance = null;

  @Watch('chartData', { deep: true })
  async dataChange() {
    await this.$nextTick();
    this.initCharts();
  }

  handleEvent(params) {
    this.$emit('click', params);
  }

  initCharts() {
    this.chartsInstance = echarts.init(this.$refs.chartsRef);
    this.chartsInstance.on('click', this.handleEvent);
    /**@type {EChartsOption} */
    const options = {
      color: this.colorArray,
      graphic: {
        elements: [
          {
            type: 'image',
            id: 'pie-bg',
            style: {
              image: require('@/assets/images/screen/pie-chart-bg.svg'),
              width: nowSize(this.size),
              height: nowSize(this.size),
              x: 0,
              y: 0,
            },
            left: this.left,
            top: 'center',
          },
        ],
      },
      tooltip: {
        trigger: 'item',
        backgroundColor: 'rgba(0,0,0,0.5)',
        textStyle: {
          color: '#D8E1F3',
          fontSize: nowSize(14),
        },
      },
      legend: {
        type: 'scroll',
        orient: 'vertical',
        right: this.right,
        top: 'center',
        height: '80%',
        itemHeight: nowSize(10),
        itemWidth: nowSize(10),
        itemGap: nowSize(this.legendItemGap),
        textStyle: {
          rich: {
            a: {
              color: '#D8E1F3',
              fontSize: nowSize(14),
              padding: [0, 20, 0, 20],
              width: nowSize(this.legendWidth),
              overflow: 'truncate',
              ellipsis: '...',
            },
            b: {
              color: '#fff',
              textShadowColor: '#7DB6FF',
              textShadowBlur: 8,
              fontSize: nowSize(20),
            },
          },
        },
        pageTextStyle: {
          color: '#D8E1F3',
          fontSize: nowSize(16),
        },
        pageIconColor: '#AAAAAA',
        pageIconInactiveColor: '#2F4554',
        pageIconSize: nowSize(16),
        formatter: name => {
          const { value } = this.chartData?.find(e => e.name === name);
          return name.length > this.fontLength && this.showEllipsis
            ? `{a|${name.substr(0, this.fontLength) + '...'}}{b|${value || 0}}`
            : `{a|${name}}{b|${value || 0}}`;
        },
      },
      series: [
        {
          type: 'pie',
          radius: ['62%', '85%'],
          avoidLabelOverlap: false,
          width: nowSize(this.size),
          height: nowSize(this.size),
          minAngle: 10,
          left: this.left,
          top: 'center',
          label: this.labelOption,
          data: this.chartData,
        },
      ],
    };
    this.chartsInstance.setOption(options, true);
    this.chartResize();
  }

  chartResize() {
    setTimeout(() => {
      const rect = this.$refs.chartsRef.getBoundingClientRect();
      const opt = {
        width: rect.width,
        height: rect.height,
      };
      if (this.chartsInstance) {
        this.chartsInstance.resize(opt);
      }
    }, 100);
  }

  mounted() {
    setTimeout(this.initCharts, 100);
    Emitter.$on('fullscreen', this.chartResize);
  }
  beforeDestroy() {
    Emitter.$off('fullscreen', this.chartResize);
    this.chartsInstance.off('click', this.handleEvent);
  }
}
</script>

<style></style>
